import React from "react";
import Calendar from './Calendar';

function App() {
  return (
    <Calendar/>
  );
}

export default App;
